import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7106179498151'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "HALF CEST WIDTH": [
            "",
            "19½/50",
            "20½/52",
            "21¼/54",
            "22/56",
            "22¾/58",
            "23½/60",
            "24½/62",
            ""
        ],
        "LENGHT FROM CENTER FRONT": [
            "",
            "23¼/59",
            "23½/60",
            "24/61",
            "24½/62",
            "24¾/63",
            "25/64",
            "25½/65",
            ""
        ],
        "SHOULDER WIDTH": [
            "",
            "16½/42",
            "17/43",
            "17¼/44",
            "17½/45",
            "18/46",
            "18½/47",
            "18¾/48",
            ""
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - Bedford Hoodie" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify